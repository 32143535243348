.slides {
  height: 100vh;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.slide {
  /* display: flex; */
  color: white;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
}

.slide::before {
  display: block;
  content: "";
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* width: 100%; */
  /* height: 100%; */
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.slide h2 {
  position: relative;
  opacity: 0;
  transition: all 600ms ease;
  transition-delay: 300ms;
}

.slide.active h2 {
  opacity: 1;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 10px;
}

.buttons button {
  margin: 0 5px;
  cursor: pointer;
}

.dots {
  margin: 0;
  margin-right: 80px;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dots li button {
  font-size: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.dots li button::before {
  font-size: 26px;
  content: "•";
  color: #888;
}

.dots li.active button::before {
  color: white;
}

button {
  cursor: pointer;
}

.login-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;

  /* identical to box height */
  text-align: center;

  color: #ffffff;
}
.login-input-container {
  background: #ffffff;
  backdrop-filter: blur(8px);
  height: 169px;
  margin-left: 15px;
  margin-right: 15px;
  /* width: 50%; */
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}
