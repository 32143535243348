/* Reset CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

html {
  font-size: 10px;
}

/* padding left */

.pl-5 {
  padding-left: 0.5rem;
}

.pl-10 {
  padding-left: 0.5rem;
}

/* overlay */
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 0, 15, 0.596);
  transform: scale(1);
  transition: all 0.4s ease;
}

/* cursor */
.pointer {
  cursor: pointer;
}

/* margin top */
.mt-10 {
  margin-top: 1rem;
}
.mt-20 {
  margin-top: 2rem;
}

.mt-30 {
  margin-top: 3rem;
}

.mt-40 {
  margin-top: 4rem;
}

/* width */
.w-100 {
  width: 100%;
}

/* text style */
.l-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 5rem;
  line-height: 5.9rem;
  /* line-height: 38px; */
  /* identical to box height */

  text-align: center;

  color: #fffcfc;
}

input[value^="Your Value"] {
  color: #040404 !important;
}

input::placeholder {
  color: rgba(4, 4, 4, 0.4);
}

.heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 3.8rem;
  /* identical to box height */

  text-align: center;

  color: #fffcfc;
}

.heading13 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 3.8rem;
  /* identical to box height */

  text-align: center;

  color: #fffcfc;
}

.bottom {
  position: absolute;
  bottom: 8%;
}

.welcome {
  min-height: 100vh;
  height: 100vh;
  position: relative;
  background: url("../assets/images/background.png") no-repeat center center/cover;
}

.flex {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-bottom {
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 10%;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.al-center {
  display: flex;
  align-items: center;
}

.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-center {
  display: flex;
  justify-content: center;
}

.logoImg {
  /* width: 15%; */
  width: 26rem;
  height: auto;
}

.sm-img {
  width: 14rem;
  height: auto;
}
.smImg {
  width: 4rem;
  height: auto;
}

.btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  /* identical to box height */

  text-align: center;
  color: #ffffff;
}

.btn-outline {
  background: rgba(4, 4, 4, 0.1);
  border: 2px solid #ffffff;
  border-radius: 9.4rem;
  /* padding: 1.6rem 9.9rem; */
  width: 25.4rem;
  height: 5.4rem;
}

.input-container {
  width: 63.5rem;
  height: 16.9rem;
  background: #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  overflow: hidden;
}

.sm-input-container {
  width: 63.5rem;
  height: 8.6rem;
  background: #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lg-input-container {
  width: 63.5rem;
  max-height: auto;
  background: #ffffff;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  overflow: hidden;
}

.input {
  width: 100%;
  height: 50%;
  border: 0px solid #dadada;
  padding: 0 3rem;
  font-size: 1.8rem;
  font-weight: 400;
  border-radius: 8px;
  font-family: "Roboto";
  /* color: #040404; */
}

.input:last-child {
  border: none;
}

.lg-input {
  width: 100%;
  height: 73px;
  border: 0px solid rgba(218, 218, 218, 1);
  padding: 0 3rem;
  font-size: 1.8rem;
  font-weight: 400;
  color: #040404;
  font-family: "Roboto";
  
}

.lg-input:last-child {
  border: none;
}

.scroll {
  overflow: auto;
}

.selected-mood {
  background: rgba(152, 54, 250, 0.1);
}

.selected-mood h2 {
  color: #9836fa;
}

.lg-mood {
  width: 100%;
  height: 73px;
  border: none;
  font-size: 1.8rem;
  font-weight: 400;
  color: rgba(4, 4, 4, 0.4);
}

.divider {
  width: 100%;
  height: 1.5px;
  background: #dadada;
}

.lg-input:focus {
  outline: none;
}

.input:focus {
  outline: none;
}

.top-position {
  position: absolute;
  top: 10%;
}

.bottom-position {
  position: absolute;
  bottom: 5%;
}

.dot-wrapper {
  position: fixed;
  right: 0%;
  margin-right: 80px;
}

.dot {
  width: 10px;
  height: 10px;
  background: rgba(253, 253, 253, 0.5);
  border-radius: 10px;
  cursor: pointer;
}

.dot-active {
  background: #fdfdfd;
}

.dot:not(:last-of-type) {
  margin-bottom: 20px;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #040404;
}

/* animation */
/* ============================== */
/* bottom to position */
/* top to position */
/* position to bottom => hide */
/* position to top  => hide*/
/* ============================== */

/* bottom to position */
.transform-bottomToPos {
  position: relative;
  animation-name: bottomToPos;
  animation-duration: 1s;
}

/* position to top  => hide*/
.transform-positionToTop {
  position: relative;
  animation-name: positionToTop;
  animation-duration: 1s;
}

/* position to bottom  => hide*/
.transform-positionToBottom {
  position: relative;
  animation-name: positionToTop;
  animation-duration: 1s;
}

/* top to position  => hide*/
.transform-positionToBottom {
  position: relative;
  animation-name: positionToBottom;
  animation-duration: 1s;
}

/* bottom to position */
@keyframes bottomToPos {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}

/* position to top  => hide*/
@keyframes positionToTop {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes moveTopBottom {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
    visibility: hidden;
  }
}

a, a:visited { outline: none; }
a:hover,
a:active,
a:focus {
  /*Edit at will*/
  background: #ccc;  
}

@media (min-width:320px)  {
   /* smartphones, iPhone, portrait 480x320 phones */ 
   .lg-input {
    height: 60px;
   }
  }
@media (min-width:481px)  {
   /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
   .lg-input {
    height: 60px;
   }
  
  }
@media (min-width:641px)  {
   /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
   .lg-input {
    height: 60px;
   }
  }
@media (min-width:961px)  {
   /* tablet, landscape iPad, lo-res laptops ands desktops */ 
   .lg-input {
    height: 60px;
   }
  }
@media (min-width:1025px) { 
  /* big landscape tablets, laptops, and desktops */
 }
@media (min-width:1281px) {
   /* hi-res laptops and desktops */ 
  
  }