@media only screen and (max-width: 700px) {
  html {
    font-size: 8px;
  }

  .sm-img {
    width: 20%;
  }

  .welcome {
    /* height: 100vh; */
  }

  .input-container {
    width: 80%;
    height: 16rem;
    border-radius: 8px;
  }

  .sm-input-container {
    width: 80%;
    height: 8rem;
  }

  .lg-input-container {
    width: 80%;
    height: auto;
  }

  .dot-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 895px) {
  .dot-wrapper {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .welcome {
    width: 100%;
    min-height: auto;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}
