.slides {
  height: 250px;
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.__1q9n52d {
  display: none;
}
.__1xc0f9k {
  margin-top: 0px;
  margin-bottom: 0px;
  /* min-height: 0px !important; */
}
.PlayerRSWP {
  box-sizing: border-box;
  font-size: inherit;
  width: 100%;
  /* min-height: 0px !important; */
}
.PlayerRSWP * {
  width: 100%;
}
.__77inad p a {
  color: #333;
  /* display: none; */
  width: 100%;
  /* background-color: red; */
  height: 0px;
  overflow: hidden;
}
.__mvqn38 img {
  height: 48px;
  width: 48px;
  display: none;
}
.PlayerRSWP button {
  display: none;
}
/* ._ContentRSWP {
  display: none;
} */
._InfoRSWP a {
  display: inline-flex;
  text-decoration: none;
  width: auto;
}
._InfoRSWP.rswp__active {
  /* display: none; */
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c4b64;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.slide {
  /* display: flex; */
  color: white;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
}

.slide::before {
  display: block;
  content: "";
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* width: 100%; */
  /* height: 100%; */
  top: 0;
  left: 0;
  position: absolute;
  z-index: 0;
}

.slide h2 {
  position: relative;
  opacity: 0;
  transition: all 600ms ease;
  transition-delay: 300ms;
}

.slide.active h2 {
  opacity: 1;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  position: fixed;
  bottom: 10px;
}

.buttons button {
  margin: 0 5px;
  cursor: pointer;
}

.dots {
  margin: 0;
  margin-right: 80px;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dots li button {
  font-size: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.dots li button::before {
  font-size: 26px;
  content: "•";
  color: #888;
}

.dots li.active button::before {
  color: white;
}


  .slides {
    height: 250px;
    transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .slide {
    /* display: flex; */
    
    color: white;
    justify-content: center;
    align-items: center;
    background-size: cover;
    position: relative;
  }
  
  .slide::before {
    display: block;
    content: '';
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* width: 100%; */
    /* height: 100%; */
    top: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }
  
  .slide h2 {
    position: relative;
    opacity: 0;
    transition: all 600ms ease;
    transition-delay: 300ms;
  }
  
  .slideactive  {
    opacity: 1;
  }
  
  .buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 10px;
  }
  
  .buttons button {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dots {
    margin: 0;
    margin-right: 80px;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    .dots { 
      display: none;
    }
  }
  .dots li button {
    font-size: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .dots li button::before {
    font-size: 35px;
    content: '•';
    color: #888;
  }
  
  .dots li.active button::before {
    color: white;
  }